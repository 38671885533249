import React, { Fragment } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Box from "common/src/components/Box";
import Text from "common/src/components/Text";
import Heading from "common/src/components/Heading";
import Button from "common/src/components/Button";
import Image from "common/src/components/Image";
import FeatureBlock from "common/src/components/FeatureBlock";
import Container from "common/src/components/UI/Container";
import BannerBG from "common/src/assets/image/crypto/white_bg1.svg";
import BannerWrapper, { BgImageWrapper } from "./bannerSection.style";

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  outlineBtnStyle
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <Button title="Get Started" {...btnStyle} />
      {/*       <Button
        title="Download Whitepaper"
        variant="textButton"
        icon={<i className="flaticon-next" />}
        {...outlineBtnStyle}
      /> */}
    </Fragment>
  );
  const ShareButtonGroup = () => (
    <Fragment>
      <div style={{ height: 200 }}></div>
      {/*       <Button
        title="Share on Facebook"
        variant="textButton"
        iconPosition="left"
        icon={<Icon icon={facebook2} />}
        {...outlineBtnStyle}
        className="btnWithoutColor"
      />
      <Button
        title="Share on LinkedIn"
        variant="textButton"
        iconPosition="left"
        icon={<Icon icon={facebook2} />}
        {...outlineBtnStyle}
        className="btnWithoutColor"
      /> */}
    </Fragment>
  );
  return (
    <BannerWrapper id="banner_section">
      {/*       <Particles /> */}
      <BgImageWrapper>
        <Image src={BannerBG} alt="banner background" />
      </BgImageWrapper>
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={<Heading content="Instantly Recieve an EIN" {...title} />}
              description={
                <Text
                  content="Direct EIN offers a fast, cost effecient solution for getting you an EIN / Tax ID Number immediately."
                  {...description}
                />
              }
              button={
                <Link to="/get-ein/start">
                  <ButtonGroup />
                </Link>
              }
            />
            <FeatureBlock button={<ShareButtonGroup />} />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center"
  },
  col: {
    pr: "15px",
    pl: "15px",
    width: ["100%", "100%", "100%", "60%", "65%"]
  },
  title: {
    fontSize: ["26px", "34px", "28px", "42px", "44px"],
    fontWeight: "700",
    color: "#fff",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px"],
    lineHeight: "1.31"
  },
  description: {
    fontSize: ["14px", "16px", "18px", "18px", "20px"],
    color: "#fff",
    lineHeight: "30px",
    mb: "0",
    maxWidth: ["400px", "400px", "380px", "400px", "550px"]
  },
  btnStyle: {
    minWidth: ["120px", "156px"],
    fontSize: "15px",
    fontWeight: "700",
    borderRadius: "6px"
  },
  outlineBtnStyle: {
    minWidth: ["130px", "156px"],
    fontSize: "16px",
    fontWeight: "700",
    color: "#fff",
    p: "5px 10px"
  }
};

export default BannerSection;
