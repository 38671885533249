import React from "react";
import Container from "common/src/components/UI/Container";
import TestimonialSecWrapper from "./bannerSlider.style";

export default () => {
  return (
    <TestimonialSecWrapper id="testimonial_section">
      <Container>
        <div style={{ width: "440px", height: "225px" }}>
          {/*           <GlideCarousel
            options={carouselOptions}
            bullets={true}
            numberOfBullets={4}
            controls={false}
          >
            <>
              {Data.cryptoJson.TESTIMONIALS.map((slideItem, index) => (
                <GlideSlide key={`testimonial-slide-${index}`}>
                  <TestimonialItem className="testimonial_item">
                    <Text content={slideItem.review} {...reviewStyle} />
                    <Box {...TestimonialMeta}>
                      <ImageWrapper>
                        <Image
                          fluid={
                            (slideItem.avatar !== null) | undefined
                              ? slideItem.avatar.childImageSharp.fluid
                              : {}
                          }
                          alt={`reviewer-image-${index}`}
                        />
                      </ImageWrapper>
                      <Box>
                        <Heading content={slideItem.name} {...nameStyle} />
                        <Text
                          content={slideItem.designation}
                          {...designationStyle}
                        />
                      </Box>
                    </Box>
                  </TestimonialItem>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel> */}
        </div>
      </Container>
    </TestimonialSecWrapper>
  );
};

TestimonialSecWrapper.defaultProps = {
  reviewStyle: {
    fontSize: ["16px", "16px", "16px", "16px", "16px"],
    fontWeight: "400",
    color: "rgb(82, 95, 127)",
    lineHeight: "1.74",
    mb: ["30px", "30px", "30px", "30px", "40px"]
  },
  TestimonialMeta: {
    flexBox: true,
    alignItems: "center"
  },
  nameStyle: {
    as: "h3",
    fontSize: ["16px", "17px", "18px", "18px", "20px"],
    fontWeight: "500",
    color: "rgb(50, 50, 93)",
    mb: "5px",
    fontFamily: "Poppins"
  },
  designationStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "rgb(82, 95, 127)",
    mb: "0",
    fontFamily: "Poppins"
  }
};
