import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
/* import ImageGallery from "react-image-gallery"; */
import Button from "common/src/components/Button";
import Box from "common/src/components/Box";
import Text from "common/src/components/Text";
import Heading from "common/src/components/Heading";
import FeatureBlock from "common/src/components/FeatureBlock";
import Container from "common/src/components/UI/Container";
import { BetaSectionWrapper, FeatureSection } from "./betaSection.style";
import Image2 from "common/src/assets/image/crypto/slider-2.png";
const images = [
  {
    original: `${Image2}`,
    originalAlt: "slide two"
  }
];

const BetaSection = ({
  row,
  title,
  description,
  featureDescriptionStyle,
  button
}) => {
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        BETA_FEATURE {
          title
          des
          image {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <BetaSectionWrapper id="api">
      <Container noGutter mobileGutter>
        <Box className="row" {...row}>
          <FeatureBlock
            title={<Heading {...title} />}
            description={<Text {...description} />}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href="mailto:hello@directein.com?subject=Direct%20EIN%20API">
              <Button {...button} title="Request API Access" />
            </a>
          </div>
        </Box>
        {/*         <Box className="FeatureSlider">
          <ImageGallery
            items={images}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            showBullets={false}
            autoPlay={false}
          />
        </Box> */}
      </Container>
    </BetaSectionWrapper>
  );
};

// Transactions style props
BetaSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  row: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object
};

// Trusted default style
BetaSection.defaultProps = {
  // Trusted section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center"
  },

  title: {
    content: "External API",
    fontSize: ["24px", "26px", "30px", "36px", "40px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    fontWeight: "700",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "20px",
    textAlign: ["center", "center"]
  },

  description: {
    content:
      "Direct EIN offers a complete api core for processing Employee Identification Numbers as well as a dashboard for managing all EIN requests and status. Free to apply and offers flexible pricing plans to meet your need.",
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: ["25px", "25px", "30px", "30px", "45px"],
    textAlign: ["center", "center"]
  },
  featureTitleStyle: {
    fontSize: ["18px", "18px", "20px", "20px", "20px"],
    lineHeight: ["1", "1", "1", "1", "1"],
    fontWeight: "500",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "10px",
    textAlign: ["left", "left"]
  },
  // Trusted section description default style
  featureDescriptionStyle: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "27px",
    textAlign: ["left", "left"]
  },
  button: {
    type: "button",
    position: "relative",
    fontSize: `${2}`,
    fontWeight: "600",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "secondaryWithBg",
    height: `${4}`
  }
};

export default BetaSection;
