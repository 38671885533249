import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Box from "common/src/components/Box";
import Text from "common/src/components/Text";
import Heading from "common/src/components/Heading";
import Button from "common/src/components/Button";
import FeatureBlock from "common/src/components/FeatureBlock";
import Input from "common/src/components/Input";
import Container from "common/src/components/UI/Container";

import ContactFromWrapper, { SectionMainWrapper } from "./contact.style";

const ContactSection = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  button,
  note,
  title,
  description
}) => {
  return (
    <SectionMainWrapper>
      <Box {...sectionWrapper}>
        <Container className="containerClass">
          <Box {...secTitleWrapper}>
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
            />
          </Box>
          <Box {...row}>
            <Box {...contactForm}>
              <ContactFromWrapper>
                <Input
                  inputType="email"
                  placeholder="Enter Your Email address"
                  iconPosition="right"
                  isMaterial={false}
                  className="email_input"
                  aria-label="email"
                />
                <Link className="navbar_button" to="/get-ein/start">
                  <Button {...button} title="Get Started" />
                </Link>
              </ContactFromWrapper>
            </Box>
          </Box>
        </Container>
      </Box>
    </SectionMainWrapper>
  );
};

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object
};

ContactSection.defaultProps = {
  sectionWrapper: {
    id: "contact_section",
    as: "section",
    pt: ["8px", "80px", "80px", "80px"],
    pb: ["0", "80px", "80px", "80px", "80px"]
  },
  secTitleWrapper: {
    mb: ["40px", "40px", "40px"],
    p: ["0 15px", 0, 0, 0, 0]
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: `${2}`,
    letterSpacing: "0.15em",
    fontWeight: `${6}`,
    color: "primary",
    mb: `${3}`
  },
  secHeading: {
    textAlign: "center",
    fontSize: [`${6}`, `${8}`],
    fontWeight: "400",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: `${0}`
  },
  row: {
    flexBox: true,
    justifyContent: "center"
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2]
  },
  button: {
    type: "button",
    fontSize: `${2}`,
    fontWeight: "600",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg",
    height: `${4}`
  },
  note: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: ["25px", "25px", "30px", "30px", "45px"],
    textAlign: ["center", "center"]
  },
  colornote: {
    fontSize: "16px",
    fontWeight: "500",
    color: "rgb(106, 82, 253)",
    lineHeight: "28px",
    mb: ["25px", "25px", "30px", "30px", "45px"],
    textAlign: ["center", "center"]
  },
  title: {
    content: "Get an EIN Now.",
    fontSize: ["20px", "26px", "30px", "36px", "40px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    fontWeight: "700",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "20px",
    textAlign: ["center", "center"]
  },

  description: {
    content:
      "Start by completeing 5 minutes worth compliance questions. Then Instantly recieve an EIN / Tax ID.",
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: ["25px", "25px", "30px", "30px", "45px"],
    textAlign: ["center", "center"]
  }
};

export default ContactSection;
